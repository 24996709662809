import { ValidationMapping } from '../data/data-common-types';
import { Dayjs } from 'dayjs';
import { TextTemplateType } from '../data/text-templates-types';

export function getInitials(name: string) {
  let initials = '';
  name?.split(' ').forEach(function (word) {
    initials += word.charAt(0).toUpperCase();
  });
  return initials;
}

export const generateGUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export const clearValidationMessages = (validationMappings: ValidationMapping[]) => {
  validationMappings.forEach(mapping => mapping.setError(''));
};

export const htmlizeMultilineText = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

export const updateValidationMessages = (
  validationMappings: ValidationMapping[],
  errorResult: any,
  onErrorFound?: (mapping: ValidationMapping) => void,
) => {
  if (errorResult.errors) {
    for (const [errorField, errorMessage] of Object.entries(errorResult.errors)) {
      const validationMapping = validationMappings.find(mapping => mapping.field.toLowerCase() === errorField.toLowerCase());
      if (validationMapping) {
        validationMapping.setError(`${errorMessage}`);
        if (onErrorFound) onErrorFound(validationMapping);
      }
    }
  }
};

export const quantizeTime = (time?: Dayjs): Dayjs | undefined => {
  if (!time) return undefined;
  const minutes = time.minute(); // get the current minute
  const roundedMinutes = Math.ceil(minutes / 30) * 30; // round up to the closest half hour
  return time.startOf('hour').add(roundedMinutes, 'minute');
};

export const dayjsToJsonString = (date: Dayjs): string => {
  return date.format('YYYY-MM-DDTHH:mm:ss');
};

export const dayjsToJsonTimeString = (date: Dayjs): string => {
  return date.format('HH:mm:ss');
};

export interface TemplateDynamicField {
  fieldCode: string;
  fieldValue: string;
}

export const calculateSMSSize = (textTemplate: string, dynamicFields?: TemplateDynamicField[]) => {
  let adaptedTextTemplate =
    textTemplate.replace(/{recipient_name}/g, 'Jack').replace(/{invitation_url}/g, 'betterbunch.com/r/inOXh') + 'Reply STOP to opt out';

  if (dynamicFields) {
    dynamicFields.forEach(field => {
      adaptedTextTemplate = adaptedTextTemplate.replace(new RegExp(`${field.fieldCode}`, 'g'), field.fieldValue);
    });
  }

  adaptedTextTemplate = adaptedTextTemplate.replace(/{sender_name}/g, 'Lui Kang').replace(/{business_name}/g, 'Mortal Kombat');

  return adaptedTextTemplate.length;
};

export const getAllSMSInvitationTemplateAlerts = (textTemplate: string, dynamicFields?: TemplateDynamicField[]) => {
  let errors: string[] = [];

  if (textTemplate.indexOf('{invitation_url}') === -1) {
    errors.push(`Add {invitation_url} to your message if you want 
					to display the invitation link within your message.`);
  }

  if (textTemplate.indexOf('{recipient_name}') === -1) {
    errors.push(`Add {recipient_name} to your message if you want 
					to display the customer name within your message.`);
  }

  if (calculateSMSSize(textTemplate, dynamicFields) > 640) {
    errors.push('This message can not exceed 640 characters');
  } else if (calculateSMSSize(textTemplate, dynamicFields) > 480) {
    errors.push('This message will use 4 SMS credits (1 credit = 160 characters)');
  } else if (calculateSMSSize(textTemplate, dynamicFields) > 320) {
    errors.push('This message will use 3 SMS credits (1 credit = 160 characters)');
  } else if (calculateSMSSize(textTemplate, dynamicFields) > 160) {
    errors.push('This message will use 2 SMS credits (1 credit = 160 characters)');
  }

  return errors;
};

export const canSaveSMSInvitationTemplate = (textTemplate: string, dynamicFields?: TemplateDynamicField[]) => {
  if (textTemplate.indexOf('{invitation_url}') === -1) return false;

  return calculateSMSSize(textTemplate, dynamicFields) <= 640;
};

export const getAllTextTemplateErrors = (textTemplate: string, templateType: TextTemplateType) => {
  let errors: string[] = [];

  if (templateType === TextTemplateType.PromoterRetargetingTemplate) return errors;

  if (textTemplate.indexOf('{recipient_name}') === -1) {
    errors.push(`Add {recipient_name} to your message if you want 
					to display the customer name within your message.`);
  }

  if (templateType === TextTemplateType.InvitationSMSTemplate) {
    return [];
  } else if (templateType === TextTemplateType.InvitationEmailTemplate) {
  }
  return errors;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function isEmailValid(email: string): boolean {
  // Regular expression to match the email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Use the test() method to check if the email matches the regex
  return emailRegex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string) {
  if (!phoneNumber || typeof phoneNumber !== 'string') {
    return false;
  }

  const phoneRegex = /^(\+|00)?(64|61|0)?((2|4)\d{7,9})$/;
  const truncatedPhoneNumber = phoneNumber.replace(/[^\d+]+/g, '');
  const match = phoneRegex.exec(truncatedPhoneNumber);

  return !!match;
}

export const cutText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
