/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import BBLogoImage from 'assets/images/better_bunch_site_logo.svg';
import reviewsIcon from 'assets/images/reviews_icon.svg';
import reviewsActiveIcon from 'assets/images/reviews_active_icon.svg';
import feedbackIcon from 'assets/images/feedback_icon.svg';
import feedbackActiveIcon from 'assets/images/feedback_active_icon.svg';
import dashboardIcon from 'assets/images/dashboard_icon.svg';
import dashboardActiveIcon from 'assets/images/dashboard_active_icon.svg';
import invitationsIcon from 'assets/images/invitations_icon.svg';
import invitationsActiveIcon from 'assets/images/invitations_active_icon.svg';
import analyticsIcon from 'assets/images/analytics_icon.svg';
import analyticsActiveIcon from 'assets/images/analytics_active_icon.svg';
import automationIcon from 'assets/images/automation_icon.svg';
import automationActiveIcon from 'assets/images/automation_active_icon.svg';
import shareablesIcon from 'assets/images/shareables_icon.svg';
import shareablesActiveIcon from 'assets/images/shareables_active_icon.svg';
import addSourceIcon from 'assets/images/add_source.svg';
import helpIcon from 'assets/images/help_icon.svg';
import settingsIcon from 'assets/images/settings_icon.svg';
import settingsActiveIcon from 'assets/images/settings_active_icon.svg';
import leftArrowIcon from 'assets/images/left_arrow.svg';
import rightArrowIcon from 'assets/images/right_arrow.svg';
import adminToolsIcon from 'assets/images/admin_tools_icon.svg';
import adminToolsActiveIcon from 'assets/images/admin_tools_selected_icon.svg';

import { InvitationStyleType } from 'data/data-common-types';

import dislike0Icon from 'assets/images/style_0_dislike.png';
import dislike1Icon from 'assets/images/style_1_dislike.png';
import dislike2Icon from 'assets/images/style_2_dislike.png';

import dislike3Icon from 'assets/images/style_3_dislike.png';
import like0Icon from 'assets/images/style_0_like.png';
import like1Icon from 'assets/images/style_1_like.png';
import like2Icon from 'assets/images/style_2_like.png';
import like3Icon from 'assets/images/style_3_like.png';
import { ComponentPropsWithoutRef } from 'react';

export const featureIconStyle = css``;

interface ArrowProps {
  addCss?: SerializedStyles;
}

export const LeftArrowIcon = ({ addCss }: ArrowProps) => (
  <img src={leftArrowIcon} css={css(addCss, { width: 6.59, height: 11.17 })} alt="left" />
);

export const RightArrowIcon = () => <img src={rightArrowIcon} css={{ width: 6.59, height: 11.17 }} alt="right" />;

export const AddSourceIcon = () => (
  <img css={{ width: 36, height: 36, cursor: 'pointer' }} src={addSourceIcon} alt="Add" />
);

interface IconProps extends ComponentPropsWithoutRef<'img'> {
  invitationStyle?: InvitationStyleType;
}

export const LikeIcon = ({ invitationStyle, ...props }: IconProps) => (
  <img
    className={`invitation-style-${invitationStyle}`}
    data-ph-capture-attribute-invite-option="like"
    data-ph-capture-attribute-invite-style={invitationStyle}
    src={
      invitationStyle === InvitationStyleType.Hands
        ? like0Icon
        : invitationStyle === InvitationStyleType.Smileys
        ? like1Icon
        : invitationStyle === InvitationStyleType.PeopleFullHeight
        ? like2Icon
        : invitationStyle === InvitationStyleType.CircledFaces
        ? like3Icon
        : like0Icon
    }
    alt="Good"
    {...props}
  />
);

export const DislikeIcon = ({ invitationStyle, ...props }: IconProps) => (
  <img
    className={`invitation-style-${invitationStyle}`}
    data-ph-capture-attribute-invite-option="dislike"
    data-ph-capture-attribute-invite-style={invitationStyle}
    src={
      invitationStyle === InvitationStyleType.Hands
        ? dislike0Icon
        : invitationStyle === InvitationStyleType.Smileys
        ? dislike1Icon
        : invitationStyle === InvitationStyleType.PeopleFullHeight
        ? dislike2Icon
        : invitationStyle === InvitationStyleType.CircledFaces
        ? dislike3Icon
        : dislike0Icon
    }
    alt="Bad"
    {...props}
  />
);

export const AutomationIcon = () => (
  <img style={{ width: 20 }} css={featureIconStyle} src={automationIcon} alt="Automation" />
);

export const InfoIcon = () => (
  <img style={{ width: 16, height: 16, marginLeft: 8, marginBottom: 4 }} src={helpIcon} alt="More info" />
);

export const AutomationActiveIcon = () => (
  <img style={{ width: 20 }} css={featureIconStyle} src={automationActiveIcon} alt="Automation" />
);

export const AnalyticsIcon = () => (
  <img style={{ width: 20, height: 20 }} css={featureIconStyle} src={analyticsIcon} alt="Analytics" />
);

export const AnalyticsActiveIcon = () => (
  <img style={{ width: 20, height: 20 }} css={featureIconStyle} src={analyticsActiveIcon} alt="Analytics" />
);

export const InvitationsIcon = () => (
  <img style={{ width: 20, height: 18 }} css={featureIconStyle} src={invitationsIcon} alt="Invitations" />
);

export const InvitationsActiveIcon = () => (
  <img style={{ width: 20, height: 18 }} css={featureIconStyle} src={invitationsActiveIcon} alt="Invitations" />
);

export const DashboardIcon = () => (
  <img style={{ width: 18, height: 18 }} css={featureIconStyle} src={dashboardIcon} alt="Dashboard" />
);

export const DashboardActiveIcon = () => (
  <img style={{ width: 18, height: 18 }} css={featureIconStyle} src={dashboardActiveIcon} alt="Dashboard" />
);

export const AdminToolsIcon = () => (
  <img style={{ width: 20, height: 20 }} css={featureIconStyle} src={adminToolsIcon} alt="Admin tools" />
);

export const AdminToolsActiveIcon = () => (
  <img style={{ width: 20, height: 20 }} css={featureIconStyle} src={adminToolsActiveIcon} alt="Admin tools" />
);

export const HelpIcon = () => (
  <img style={{ width: 18.5, height: 18.5 }} css={featureIconStyle} src={helpIcon} alt="Help" />
);

export const SettingsIcon = () => (
  <img style={{ width: 18, height: 19 }} css={featureIconStyle} src={settingsIcon} alt="Settings" />
);

export const SettingsActiveIcon = () => (
  <img style={{ width: 18, height: 19 }} css={featureIconStyle} src={settingsActiveIcon} alt="Settings" />
);

export const ReviewsIcon = () => (
  <img style={{ width: 18, height: 17 }} css={featureIconStyle} src={reviewsIcon} alt="Reviews" />
);

export const ReviewsActiveIcon = () => (
  <img style={{ width: 18, height: 17 }} css={featureIconStyle} src={reviewsActiveIcon} alt="Reviews" />
);

export const FeedbackIcon = () => (
  <img style={{ width: 18, height: 18 }} css={featureIconStyle} src={feedbackIcon} alt="Feedback" />
);

export const FeedbackActiveIcon = () => (
  <img style={{ width: 18, height: 18 }} css={featureIconStyle} src={feedbackActiveIcon} alt="Feedback" />
);

interface Props {
  className?: string;
}

export const BBLogoSignIn = ({ className }: Props) => (
  <a href="/">
    <img
      className={className}
      css={css`
        width: 225.89px;
        height: 52.55px;
      `}
      src={BBLogoImage}
      alt="BBLogo"
    />
  </a>
);

export const BBLogo = ({ className }: Props) => (
  <a href="/">
    <img
      className={className}
      css={css`
        width: 170px;
        margin: 31.39px 40px 53.38px 19px;
      `}
      src={BBLogoImage}
      alt="BBLogo"
    />
  </a>
);

export const ShareablesIcon = () => (
  <img style={{ width: 20 }} css={featureIconStyle} src={shareablesIcon} alt="Shareables" />
);

export const ShareablesActiveIcon = () => (
  <img style={{ width: 20 }} css={featureIconStyle} src={shareablesActiveIcon} alt="Shareables" />
);
