import { InvitationStyleType } from './data-common-types';

export const dynamicFieldCodes = {
  senderName: '{sender_name}',
  businessName: '{business_name}',
};

export enum TextTemplateType {
  InvitationSMSTemplate = 'InvitationSMSTemplate',
  InvitationEmailTemplate = 'InvitationEmailTemplate',
  PromoterRetargetingTemplate = 'PromoterRetargetingTemplate',
  InvitationStyle = 'InvitationStyle',
}

export interface TextTemplateDTO {
  templateType: TextTemplateType;
  templateText: string;
  predefinedTemplateTitle?: string;
  isDefault: boolean;
}

export interface TextTemplate {
  templateType: TextTemplateType;
  templateText: string;
  predefinedTemplateTitle?: string;
  isDefault: boolean;
}

export interface TextTemplatePayload {
  templates: TextTemplateDTO[];
}

export interface PredefinedTextTemplate {
  title: string;
  content: string;
  isDefault: boolean;
  isCustom: boolean;
  isIncentive: boolean;
  type: TextTemplateType;
}

export interface InvitationStyleSettings {
  invitationStyle: InvitationStyleType;
  businessLogoUrl: string;
  businessName: string;
  senderName?: string;
}
