import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/App.scss';

import { AuthProvider, useAuth } from 'data/providers/AuthProvider';
import { PostHogProvider } from 'posthog-js/react';
import { FullPageSpinner } from './components/lib';
import { BrowserRouter, useLocation } from 'react-router-dom';
import ForgotPasswordPage from './components/common/pages/ForgotPasswordPage';
import ForgotPasswordConfirmationPage from './components/common/pages/ForgotPasswordConfirmationPage';
import { ResetPasswordPage } from './components/common/pages/ResetPasswordPage';
import { store } from 'store/app/user-store/store';
import { Provider } from 'react-redux';

const SetupApp = React.lazy(() => import('./components/setup-components/SetupApp'));
const UserApp = React.lazy(() => import('./components/user-components/UserApp'));
const AdminApp = React.lazy(() => import('./components/admin-components/AdminApp'));
const PublicApp = React.lazy(() => import('./components/public-components/PublicApp'));
const SignInPage = React.lazy(() => import('./components/common/pages/SignInPage'));
const SignUpPage = React.lazy(() => import('./components/common/pages/SignUpPage'));

interface AppProps {
  baseUrl?: string;
}

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const App = ({ baseUrl }: AppProps) => (
  <BrowserRouter basename={baseUrl}>
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </PostHogProvider>
  </BrowserRouter>
);

const AppRouter = () => {
  const location = useLocation();
  const { user } = useAuth();

  if (location.pathname.startsWith('/public'))
    return (
      <React.Suspense fallback={<></>}>
        <PublicApp />
      </React.Suspense>
    );

  if (isMobile && !isTablet) {
    window.location.href = 'https://betterbunch.com/uhoh-looks-like-youre-on-a-mobile-device';
    return <></>;
  }

  if (location.pathname.startsWith('/sign-up')) {
    return (
      <React.Suspense fallback={<FullPageSpinner />}>
        <SignUpPage />
      </React.Suspense>
    );
  }

  if (!user && location.pathname.startsWith('/forgot-password-confirmation')) {
    return <ForgotPasswordConfirmationPage />;
  }

  if (!user && location.pathname.startsWith('/forgot-password')) {
    return <ForgotPasswordPage />;
  }

  if (!user && location.pathname.startsWith('/reset-password')) {
    return <ResetPasswordPage />;
  }

  if (user && !user.isAdmin && !user.isSuperAdmin && !user.hasFinishedSetup) {
    return (
      <React.Suspense fallback={<FullPageSpinner />}>
        <SetupApp />
      </React.Suspense>
    );
  }

  const createUserApp = () => (
    <Provider store={store}>
      <UserApp />
    </Provider>
  );

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? user.isAdmin || user.isSuperAdmin ? <AdminApp /> : createUserApp() : <SignInPage />}
    </React.Suspense>
  );
};

export default App;
