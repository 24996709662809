import { APIClient } from './API';

interface TimeZoneDTO {
  timeZoneId: string;
}

export const getUserTimeZone = async (token: string): Promise<string> => {
  const payload = await APIClient.AuthorizedGet<TimeZoneDTO>(token, 'user-settings/time-zone');
  return payload.timeZoneId;
};

interface BestSendingTimesDTO {
  bestThisWeek: string;
  bestWithin24Hours: string;
  sendTomorrowTime: string;
  nowDate: string;
}

export interface BestSendingTimes {
  bestThisWeek: Date;
  bestWithin24Hours: Date;
  sendTomorrowTime: Date;
  nowDate: Date;
}

export const getUserBestSendingTimes = async (token: string): Promise<BestSendingTimes> => {
  const payload = await APIClient.AuthorizedGet<BestSendingTimesDTO>(
    token,
    'user-settings/best-sending-times',
  );
  return {
    bestThisWeek: new Date(payload.bestThisWeek),
    bestWithin24Hours: new Date(payload.bestWithin24Hours),
    sendTomorrowTime: new Date(payload.sendTomorrowTime),
    nowDate: new Date(payload.nowDate),
  };
};

interface UserSettings {
  userName: string;
  userEmail: string;
  businessEmail: string;
  avatarFile?: File;
  avatarIndex?: number;
}

export const getCurrentUserSettings = async (token: string): Promise<UserSettings> => {
  return APIClient.AuthorizedGet<UserSettings>(token, 'user-settings');
};

export const updateCurrentUserSettings = async (
  token: string,
  settings: UserSettings,
): Promise<void> => {
  const formData = new FormData();
  formData.append('userName', settings.userName);
  formData.append('userEmail', settings.userEmail);
  formData.append('businessEmail', settings.businessEmail);
  if (settings.avatarFile) {
    formData.append('avatarFile', settings.avatarFile);
  }
  if (settings.avatarIndex !== undefined) {
    formData.append('avatarIndex', settings.avatarIndex.toString());
  }
  return APIClient.AuthorizedFormPut(token, 'user-settings', formData);
};

interface BusinessSettings {
  businessName: string;
  businessEmail: string;
  businessLogoUrl?: string;
  timeZoneId: string;
}

export const getCurrentUserBusinessSettings = async (token: string): Promise<BusinessSettings> => {
  return APIClient.AuthorizedGet<BusinessSettings>(token, 'business-settings');
};

interface UpdateBusinessSettingsDTO {
  businessName: string;
  logoFile?: File;
  timeZoneId: string;
}

export const updateCurrentUserBusinessSettings = async (
  token: string,
  settings: UpdateBusinessSettingsDTO,
): Promise<void> => {
  const formData = new FormData();
  formData.append('businessName', settings.businessName);
  formData.append('timeZoneId', settings.timeZoneId);
  if (settings.logoFile) {
    formData.append('logoFile', settings.logoFile);
  }
  return APIClient.AuthorizedFormPut(token, 'business-settings', formData);
};

export const updateCurrentUserBusinessName = async (
  token: string,
  businessName: string,
): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user/business-name', { businessName });
};

export enum SimproCostCenterFilterType {
  Ignore = 0,
  Include = 1,
  Exclude = 2,
}

export enum AutomationKeywordFilterType {
  Exclude = 0,
  Include = 1,
  Ignore = 2,
}

export interface AutomationKeywordFilterSettings {
  filterType: AutomationKeywordFilterType;
  textValues: string[];
}

interface InvitationRulesSettings {
  startQuietTime: string;
  endQuietTime: string;
  coolOffPeriodInDays: number;
  prioritiseSMS: boolean;
  sendingDelay: number;
  promoterRetargetingOn: boolean;
  keepCustomerName: boolean;
  isXeroConnected: boolean;
  isMyobConnected: boolean;
  generalKeywordFilter?: AutomationKeywordFilterSettings;
}

export const getCurrentUserInvitationRulesSettings = async (
  token: string,
): Promise<InvitationRulesSettings> => {
  return APIClient.AuthorizedGet<InvitationRulesSettings>(token, 'invitation-rules-settings');
};

interface UpdateInvitationRulesSettingsDTO {
  startQuietTime: string;
  endQuietTime: string;
  coolOffPeriodInDays: number;
  prioritiseSMS: boolean;
  promoterRetargetingOn: boolean;
  keepCustomerName: boolean;
  generalKeywordFilter?: AutomationKeywordFilterSettings;
  sendingDelay: number;
}

export const updateCurrentUserInvitationRulesSettings = async (
  token: string,
  settings: UpdateInvitationRulesSettingsDTO,
): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'invitation-rules-settings', settings);
};

export interface UserSecuritySettings {
  purgeSensitiveData: boolean;
  purgeSensitiveDataAfterDays: number;
}

export const getCurrentUserSecuritySettings = async (
  token: string,
): Promise<UserSecuritySettings> => {
  return APIClient.AuthorizedGet<UserSecuritySettings>(token, 'security-settings');
};

export interface UpdateUserSecuritySettingsDTO {
  purgeSensitiveData: boolean;
  purgeSensitiveDataAfterDays: number;
}

export const updateCurrentUserSecuritySettings = async (
  token: string,
  settings: UpdateUserSecuritySettingsDTO,
): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'security-settings', settings);
};

interface UpdatePasswordDTO {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const updateCurrentUserPassword = async (
  token: string,
  settings: UpdatePasswordDTO,
): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'change-password', settings);
};

export interface UserReviewSitesSettings {
  isGoogleConnected: boolean;
  googleReviewsUrl?: string;
  isGoogleReviewsConnected: boolean;
  isFacebookConnected: boolean;
  isFacebookReviewsConnected: boolean;
  isYelpConnected: boolean;
  isNoCowboysConnected: boolean;
  facebookAppId: string;
}

export const getCurrentUserReviewSitesSettings = async (
  token: string,
): Promise<UserReviewSitesSettings> => {
  return APIClient.AuthorizedGet<UserReviewSitesSettings>(token, 'review-sites-settings');
};

export const getUserSenderName = async (token: string): Promise<string> => {
  const payload = await APIClient.AuthorizedGet<{ senderName: string }>(
    token,
    'user-settings/sender-name',
  );
  return payload.senderName;
};

export const updateUserSenderName = async (token: string, senderName: string): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user-settings/sender-name', { senderName });
};

export const getUserBlocklistSize = async (token: string): Promise<number> => {
  const payload = await APIClient.AuthorizedGet<{ count: number }>(
    token,
    'user-settings/blocklist/size',
  );
  return payload.count;
};

interface BlocklistItem {
  name?: string;
  email?: string;
  phone?: string;
}

interface UpdateBlocklistPayload {
  blocklist?: BlocklistItem[];
  file?: File;
}

export const updateBlocklist = async (
  token: string,
  payload: UpdateBlocklistPayload,
): Promise<void> => {
  const formData = new FormData();
  if (payload.blocklist) {
    payload.blocklist.forEach((contact, index) => {
      formData.append(`contacts[${index}].name`, contact.name ?? '');
      formData.append(`contacts[${index}].email`, contact.email ?? '');
      formData.append(`contacts[${index}].phone`, contact.phone ?? '');
    });
  }
  if (payload.file) {
    formData.append('file', payload.file);
  }
  return APIClient.AuthorizedFormPost(token, 'user-settings/blocklist', formData);
};

export interface SearchBlocklistItemDTO {
  id: number;
  name: string;
  email: string;
  phone: string;
}

export interface SearchBlocklistResult {
  blocklist: SearchBlocklistItemDTO[];
  totalCount: number;
  pagesCount: number;
}

export const searchBlocklist = async (
  token: string,
  search?: string,
  page?: number,
  pageSize?: number,
): Promise<SearchBlocklistResult> => {
  return await APIClient.AuthorizedGet<SearchBlocklistResult>(token, 'user-settings/blocklist', {
    search,
    page,
    pageSize,
  });
};

export const deleteBlocklistItem = async (token: string, id: number): Promise<void> => {
  return APIClient.AuthorizedDelete(token, `user-settings/blocklist/${id}`);
};

export interface SubscriptionSettings {
  subscriptionType?: string;
  subscriptionStatus?: string;
  renewalDate?: string;
  subscriptionEndDate?: string;
  renewalValue?: string;
  cardLast4?: string;
  cardExpiry?: string;
}

interface HubSpotPayloadProperty {
  key: string;
  value: any;
}

export const getCurrentUserSubscriptionSettings = async (
  token: string,
): Promise<SubscriptionSettings> => {
  const payload = await APIClient.AuthorizedGet<SubscriptionSettings>(
    token,
    'user-settings/subscription-settings',
  );

  return payload;
};

export const getUserPaymentCards = async (token: string): Promise<any> => {
  return APIClient.AuthorizedGet<any>(token, 'user-settings/payment-cards');
};

export const setUserPaymentCard = async (token: string, cardId: string): Promise<void> => {
  return APIClient.AuthorizedPut(token, `user-settings/selected-payment-card`, { id: cardId });
};

export const addPaymentCard = async (token: string, cardId: string): Promise<void> => {
  return APIClient.AuthorizedPost(token, 'user-settings/payment-cards', { cardId });
};

export abstract class ConfigurationSettingKeys {
  static readonly ENVIRONMENT_TYPE = 'environmentType';
}

export const getConfigurationSetting = async <T>(token: string, key: string): Promise<T> => {
  const { value } = await APIClient.AuthorizedGet<{ value: T }>(
    token,
    'app/configuration-setting',
    {
      key,
    },
  );
  return value;
};

export const setFacebookPage = async (token: string, pageUrl: string): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user/facebook-page', { pageUrl });
};

export const cancelSubscription = async (token: string): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user-settings/cancel-subscription', {});
};

export const reactivateSubscription = async (token: string): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user-settings/reactivate-subscription', {});
};

export const deletePaymentCard = async (
  token: string,
  cardId: string,
  newCardId: string,
): Promise<void> => {
  return APIClient.AuthorizedDelete(token, `user-settings/payment-cards/${cardId}`, { newCardId });
};

export interface WidgetSettings {
  widgetFilesFolderUrl: string;
  htmlContainerUrl: string;
  apiQueryUrl: string;
  sampleWidgetUrl: string;
  useGoogleReviews: boolean;
  useFacebookReviews: boolean;
  showTimeInSeconds: number;
  startUpTimeInSeconds: number;
  showUpDelayInSeconds: number;
  enabled: boolean;
}

export const getWidgetSettings = async (token: string): Promise<WidgetSettings> => {
  return APIClient.AuthorizedGet<WidgetSettings>(token, 'user/widget-settings');
};

export interface WidgetUpdateDTO {
  useGoogleReviews: boolean;
  useFacebookReviews: boolean;
  showTimeInSeconds: number;
  startUpTimeInSeconds: number;
}

export const updateWidgetSettings = async (
  token: string,
  widgetUpdateDTO: WidgetUpdateDTO,
): Promise<void> => {
  return APIClient.AuthorizedPut(token, 'user/widget-settings', widgetUpdateDTO);
};

export const disconnectWidget = async (token: string): Promise<void> => {
  return APIClient.AuthorizedDelete(token, 'user/widget');
};
