/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useState } from 'react';

import { useAuth } from 'data/providers/AuthProvider';

import { BBLink, ExplanationText, Div40Height, Div20Height } from 'styles/app-styles';
import { BBLogoSignIn } from 'styles/Icons';
import loginImage from 'assets/images/sign_in_image.png';
import { createResetPasswordEmail } from 'data/auth-data';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordConfirmationPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, loginError } = useAuth();
  const navigate = useNavigate();

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password);
  };

  async function handleResetPassword() {
    await createResetPasswordEmail(email);
    navigate('/forgot-password-confirmation');
  }

  return (
    <div
      css={css`
        height: 100vh;
        color: #262024;
      `}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        css={css`
          border-radius: 20px;
          background-color: white;
          padding: 62px 110px;
        `}
        className="d-flex flex-column align-items-center"
      >
        <BBLogoSignIn />
        <Div20Height />
        <ExplanationText css={{ maxWidth: 400 }}>
          We have just sent a password reset email, which should arrive shortly. If the email has not arrived within
          five minutes (remember to check your junk folder), you can let us know by clicking{' '}
          <a href="mailto:help@betterbunch.com?Subject=Forgot%20password%20issue" target="_top">
            here
          </a>
          .
        </ExplanationText>
        <Div40Height />

        <div
          className="text-center"
          css={css`
            width: 400px;
            margin-top: 20px;
            color: #262024;
            font-size: 12px;
          `}
        >
          <BBLink target="_blank" href="https://betterbunch.com/terms-of-service">
            Terms of Service
          </BBLink>{' '}
          and &nbsp;
          <BBLink target="_blank" href="https://betterbunch.com/our-privacy-policy">
            Privacy Policy.
          </BBLink>
        </div>
      </div>
      <div
        css={css`
          margin-left: 130px;
        `}
      >
        <div css={{ width: 600, height: 600 }} className={'d-flex justify-content-center align-items-start'}>
          <img css={{ maxWidth: 600, maxHeight: 600 }} src={loginImage} alt="login" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirmationPage;
