import { AuthenticationClient } from 'data/auth-data';

export const authUserKey = '__auth_user__';

const logout = () => window.localStorage.removeItem(authUserKey);
const getUserDataFromStorage = () => window.localStorage.getItem(authUserKey);
const login = async (username: string, password: string) => {
  const authResult = await AuthenticationClient.login(username, password);

  if (authResult.result) {
    return {
      ...authResult,
      email: authResult.username,
      userName: authResult.username,
    };
  }

  return undefined;
};

export { login, logout, getUserDataFromStorage };
