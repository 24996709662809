import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { templateSettingsSlice } from '../../features/invitation-templates/templatesSettingsSlice';

const rootReducer = combineSlices(templateSettingsSlice);

export const makeStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = makeStore();

export type AppStore = typeof store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

export const appReducer = rootReducer;
