/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { BBLink, ExplanationText, Div40Height, Div20Height } from 'styles/app-styles';
import { BBLogoSignIn } from 'styles/Icons';
import { LoginTextInput } from 'components/custom-inputs/LoginTextInput';
import loginImage from 'assets/images/sign_in_image.png';
import { PrimaryButton } from 'components/custom-inputs/PrimaryButton';
import { createResetPasswordEmail, doesEmailLoginExist } from 'data/auth-data';
import { useNavigate } from 'react-router-dom';
import { FormInputValidationMessage } from '../../custom-inputs/FormInputValidationMessage';

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  async function handleResetPassword() {
    if (!(await doesEmailLoginExist(email))) {
      setErrorMessage(`This email address isn't associated with an account, try another one.`);
      return;
    }
    await createResetPasswordEmail(email);
    navigate('/forgot-password-confirmation');
  }

  return (
    <div
      css={css`
        height: 100vh;
        color: #262024;
      `}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        css={css`
          border-radius: 20px;
          background-color: white;
          padding: 62px 110px;
        `}
        className="d-flex flex-column align-items-center"
      >
        <BBLogoSignIn />
        <Div20Height />
        <ExplanationText css={{ maxWidth: 400 }}>
          Enter the email address that you used to register and we will send you instructions to
          reset your password.
        </ExplanationText>
        <Div40Height />

        <LoginTextInput
          autoFocus
          onTextChange={setEmail}
          label="Email"
          width="400px"
          css={css`
            margin-bottom: 20px;
          `}
        />
        <FormInputValidationMessage validationError={errorMessage} />

        <PrimaryButton
          type="submit"
          className="w-100"
          css={css`
            margin-top: 30px;
            margin-bottom: 24px;
          `}
          onClick={handleResetPassword}
        >
          Reset password
        </PrimaryButton>

        <div
          className="text-center"
          css={css`
            width: 400px;
            margin-top: 40px;
            color: #262024;
            font-size: 12px;
          `}
        >
          <BBLink target="_blank" href="https://betterbunch.com/terms-of-service">
            Terms of Service
          </BBLink>{' '}
          and &nbsp;
          <BBLink target="_blank" href="https://betterbunch.com/our-privacy-policy">
            Privacy Policy.
          </BBLink>
        </div>
      </div>
      <div
        css={css`
          margin-left: 130px;
        `}
      >
        <div
          css={{ width: 600, height: 600 }}
          className={'d-flex justify-content-center align-items-start'}
        >
          <img css={{ maxWidth: 600, maxHeight: 600 }} src={loginImage} alt="login" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
