/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Form, FormGroup } from 'react-bootstrap';
import { errorColor, fontPoppinsRegular } from '../../styles/app-styles';

interface Props {
  width?: string;
  label: string;
  onTextChange?: (newValue: string) => void;
  className?: string;
  css?: SerializedStyles;
  errorMessage?: string;
  value?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  whiteStyle?: boolean;
  maxLength?: number;
}

export const LabeledFormTextInput = (props: Props) => {
  return (
    <FormGroup
      className={props.className}
      css={css`
        ${props.width ? `width: ${props.width};` : ''};
        ${props.css ? props.css : ''};
        font-family: ${props.whiteStyle ? fontPoppinsRegular : 'Circular Std Book'};
        border-radius: ${props.whiteStyle ? '18px' : '40px'};
        background: ${props.whiteStyle ? 'white' : '#f9f6f1'};
        border: ${props.errorMessage ? '1px solid ' + errorColor : 'none'};
        display: flex;
        height: ${props.whiteStyle ? 'auto' : '56px'};
        flex-direction: column;
        padding: ${props.whiteStyle ? '8px 18px' : '6.5px 24px'};
      `}
    >
      <Form.Label
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: ${props.whiteStyle ? '11px' : '12px'};
          line-height: 160%;
          color: ${props.whiteStyle ? '#656565' : '#878d96'};
          margin: 0;
          padding: 0;
        `}
      >
        {props.label}
      </Form.Label>
      <Form.Control
        autoFocus={props.autoFocus}
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: ${props.whiteStyle ? '14px' : '16px'};
          line-height: 160%;
          border: none;
          background-color: transparent;
          outline: none;
          border-color: transparent;
          color: ${props.whiteStyle ? '#5C5C5C' : 'initial'};
          box-shadow: none;
          margin: 0;
          padding: 0;

          :focus {
            outline: none;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
          }
        `}
        defaultValue={props.defaultValue}
        value={props.value}
        type="text"
        maxLength={props.maxLength}
        onChange={e => {
          if (props.onTextChange) props.onTextChange(e.target.value);
        }}
      />
    </FormGroup>
  );
};
