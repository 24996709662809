/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { ReactNode } from 'react';

import { errorColor } from 'styles/app-styles';

type Props = {
  validationError?: string | ReactNode;
};

export const FormInputValidationMessage = ({ validationError }: Props) => {
  return validationError ? (
    <div
      css={css`
        margin-top: 4px;
        margin-left: 18px;
        width: 100%;
      `}
    >
      <p
        css={css`
          font-size: 12px;
          font-weight: 450;
          margin-bottom: 0;
          color: ${errorColor};
        `}
      >
        {validationError}
      </p>
    </div>
  ) : (
    <></>
  );
};
