import React, { useEffect, useState } from 'react';

import * as auth from '../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { authUserKey } from '../auth';
import { isCurrentUserActive } from '../auth-data';
import posthog from 'posthog-js';

export interface AuthUser {
  userName: string;
  name: string;
  businessName: string;
  email: string;
  token: string;
  expires: string;
  isAdmin: boolean;
  widgetOn: boolean;
  isSuperAdmin: boolean;
  isPro: boolean;
  hasFinishedSetup: boolean;
  businessId?: number;
  userId: string;
  iconVersion?: number;
  loggedInFromAdmin: boolean;
  features?: {
    [key: string]: boolean;
  };
}

interface IAuthContext {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  user?: AuthUser;
  setUser: (value: AuthUser) => void;
  logout: () => void;
  login: (email: string, password: string) => void;
  loginError?: string;
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  setUser: () => {},
  logout: () => {},
  login: () => {},
});

export const useAuth = () => React.useContext(AuthContext);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    const userValue = auth.getUserDataFromStorage();
    if (userValue) {
      const user: AuthUser = JSON.parse(userValue);
      setUser(user);
      setIsAuthenticated(true);
    }
  }

  const logout = () => {
    auth.logout();
    setUser(undefined);
    setIsAuthenticated(false);
    posthog.reset();
    navigate('/');
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem(authUserKey, JSON.stringify(user));
    }
  }, [user]);

  const login = async (email: string, password: string) => {
    const user = await auth.login(email, password);

    if (user) {
      if (!user.isAdmin && !user.isSuperAdmin && !user.loggedInFromAdmin && user.isDisabled) {
        window.location.replace('https://betterbunch.com/inactive-account');
      }
      setUser(user);
      localStorage.setItem(authUserKey, JSON.stringify(user));
      setLoginError(undefined);
      setIsAuthenticated(true);
      posthog.identify(user.userId, {
        email: user.email,
        name: user.name,
        business: user.businessName,
      });
    } else {
      setLoginError('Incorrect password');
    }
  };

  useEffect(() => {
    if (user) {
      if (new Date() > new Date(user.expires)) {
        logout();
      } else if (!user.isAdmin && !user.isSuperAdmin && !user.loggedInFromAdmin) {
        isCurrentUserActive(user.token).then(isActive => {
          if (!isActive) {
            logout();
          }
        });
      }
    }
  }, [user, logout]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        login,
        logout,
        loginError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
