import { Dayjs } from 'dayjs';

export enum StandardResponseStatuses {
  Success = 'success',
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
  Error = 'error',
}

export enum DateFilter {
  All = 'All',
  ThisYear = 'ThisYear',
  Last6Months = 'Last6Months',
  Last3Months = 'Last3Months',
  LastMonth = 'LastMonth',
  ThisMonth = 'ThisMonth',
  ThisWeek = 'ThisWeek',
  Custom = 'Custom',
}

export enum SiteFilter {
  All = 'All',
  Google = 'Google',
  Facebook = 'Facebook',
}

export const ReviewSources = ['google', 'facebook'];

export type ValidationMapping = {
  field: string;
  setError: (message: string) => void;
};

export enum WorkableEntityStatus {
  Unread = 'Unread',
  Read = 'Read',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Responded = 'Responded',
}

export interface HandlerDateFilterChangeProps {
  value: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export interface CreateEntityResult {
  id?: string;
  status: StandardResponseStatuses;
}

export interface ReviewChartItem {
  year: number;
  name: string;
  month: string;
  day: string;
  counters: Object;
  imageUrls: Object;
  ids: Array<number>;
}

export enum ColumnSortDirection {
  None = 'None',
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum ApplicationEnvironmentType {
  Development = 'Development',
  Production = 'Production',
}

export interface InvitationContact {
  invitationId?: number;
  email?: string;
  phoneNumber?: string;
  name: string;
}

export interface SelectListItem {
  text: string;
  value: string;
}

export enum InvitationStyleType {
  Hands = 'Hands',
  Smileys = 'Smileys',
  PeopleFullHeight = 'PeopleFullHeight',
  CircledFaces = 'CircledFaces',
}

export enum SendTimeRule {
  Immediately = 0,
  AtSpecificTime = 1,
  OptimalTime = 2,
  InXHours = 3,
  OptimalDayTime = 4,
  AtSpecificDateTime = 5,
}

export enum SendMethod {
  SMS = 'SMS',
  Email = 'Email'
}