import { APIClient } from './API';

export interface AuthResult {
  result: boolean;
  token: string;
  username: string;
  isDisabled: boolean;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
  name: string;
  businessName: string;
  businessId?: number;
  userId: string;
  expires: string;
  isAdmin: boolean;
  widgetOn: boolean;
  isSuperAdmin: boolean;
  isPro: boolean;
  hasFinishedSetup: boolean;
  loggedInFromAdmin: boolean;
}

export class AuthenticationClient {
  public static login(username: string, password: string): Promise<AuthResult> {
    return APIClient.Post<AuthResult>('authenticate', {
      username,
      password,
    });
  }

  public static async logout() {}
}

export const createResetPasswordEmail = (email: string) => {
  return APIClient.Post('auth/password-reset-email', { email });
};

export const doesEmailLoginExist = async (email: string): Promise<boolean> => {
  const { exists } = await APIClient.Post<{ exists: boolean }>(`auth/does-login-email-exist`, {
    email,
  });
  return exists;
};

export const isCurrentUserActive = async (token: string): Promise<boolean> => {
  const { isActive } = await APIClient.AuthorizedGet<{ isActive: boolean }>(token, `auth/is-this-user-active`);
  return isActive;
};
