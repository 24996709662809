import { AuthUser } from 'data/providers/AuthProvider';
import {
  getInvitationStyleSettings,
  getPredefinedTextTemplates,
  getTextTemplates,
  updateInvitationStyleSettings,
  updateTextTemplate,
} from 'data/text-templates-api';
import { InvitationStyleType } from 'data/data-common-types';
import { createAppSlice } from 'store/app/user-store/createAppSlice';
import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { ContentUpdate, TemplateSettingsState } from './templateSettingsSlice.types';
import { validateTemplateSettings } from './templateSettingsSlice.validation';
import { TextTemplateType } from 'data/text-templates-types';
import { updateCurrentUserBusinessName, updateUserSenderName } from 'data/user-settings-data';

const initialState: TemplateSettingsState = {
  isLoadingInitialData: false,
  selectedTemplateType: TextTemplateType.InvitationSMSTemplate,
  selectedInvitationStyle: InvitationStyleType.Hands,
  predefinedTemplates: [],
  userTemplates: [],
  senderName: '',
  businessName: '',
  initialHtmlTemplate: '',
  htmlTemplate: '',
  textTemplate: '',
  businessLogoUrl: '',
  inputErrors: [],
  saveErrorText: '',
  isSaving: false,
  canSave: false,
};

export const templateSettingsSlice = createAppSlice({
  name: 'templateSettings',
  initialState,
  reducers: create => ({
    initialLoadAsync: create.asyncThunk(
      async (user: AuthUser) => {
        const [predefinedTemplates, invitationSettings, userTemplates] = await Promise.all([
          getPredefinedTextTemplates(user.token),
          getInvitationStyleSettings(user.token),
          getTextTemplates(user!.token),
        ]);

        return {
          predefinedTemplates,
          invitationSettings,
          senderName: invitationSettings.senderName ?? user.name,
          userTemplates,
          user,
        };
      },
      {
        pending: state => {
          if (state.predefinedTemplates) {
            state.selectedTemplateType = initialState.selectedTemplateType;
            populatePredefinedTemplateTitle(state);
            loadUserTextTemplate(state);
          }

          state.isLoadingInitialData = true;
        },
        fulfilled: (state, action) => {
          state.predefinedTemplates = action.payload.predefinedTemplates;
          state.businessLogoUrl = action.payload.invitationSettings.businessLogoUrl;
          state.authToken = action.payload.user.token;
          state.businessName = state.initialBusinessName = action.payload.user.businessName;
          state.senderName = state.initialSenderName = action.payload.senderName;
          state.selectedInvitationStyle =
            state.oneTimeSettings?.invitationStyle ?? action.payload.invitationSettings.invitationStyle;
          state.userTemplates = action.payload.userTemplates;
          state.selectedTemplateType = TextTemplateType.InvitationSMSTemplate;

          populatePredefinedTemplateTitle(state);
          loadUserTextTemplate(state);

          state.isLoadingInitialData = false;
        },
      },
    ),
    clearOneTimeSettings: create.reducer(state => (state.oneTimeSettings = undefined)),
    setSelectedTemplateType: create.reducer((state, action: PayloadAction<TextTemplateType>) => {
      if (state.isLoadingInitialData) return;

      state.selectedTemplateType = action.payload;
      populatePredefinedTemplateTitle(state);
      loadUserTextTemplate(state);
    }),
    setTemplateTitle: create.reducer((state, action: PayloadAction<string>) => {
      if (state.isLoadingInitialData) return;

      state.selectedPredefinedTemplateTitle = action.payload;
      loadUserTextTemplate(state);
    }),
    setSelectedInvitationStyle: create.reducer((state, action: PayloadAction<InvitationStyleType>) => {
      state.selectedInvitationStyle = action.payload;
    }),
    setSenderName: create.reducer((state, action: PayloadAction<string>) => {
      state.senderName = action.payload;
      validateTemplateSettings(state);
    }),
    setBusinessName: create.reducer((state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
      validateTemplateSettings(state);
    }),
    updateTemplateContent: create.reducer((state, action: PayloadAction<ContentUpdate>) => {
      state.htmlTemplate = action.payload.htmlValue;
      state.textTemplate = action.payload.textValue;
      validateTemplateSettings(state);
    }),
    saveOneTimeSettings: create.reducer(state => {
      if (!state.oneTimeSettings) state.oneTimeSettings = {};

      if (state.selectedTemplateType === TextTemplateType.InvitationStyle) {
        state.oneTimeSettings.invitationStyle = state.selectedInvitationStyle;
      } else {
        if (state.selectedTemplateType === TextTemplateType.InvitationSMSTemplate) {
          state.oneTimeSettings.predefinedSMSTemplateTitle = state.selectedPredefinedTemplateTitle;
          state.oneTimeSettings.smsTemplate = state.htmlTemplate;
        } else if (state.selectedTemplateType === TextTemplateType.InvitationEmailTemplate) {
          state.oneTimeSettings.predefinedEmailTemplateTitle = state.selectedPredefinedTemplateTitle;
          state.oneTimeSettings.emailTemplate = state.htmlTemplate;
        }

        state.oneTimeSettings.senderName = state.senderName;
        state.oneTimeSettings.businessName = state.businessName;
      }
    }),
    saveGloballyAsync: create.asyncThunk(
      async (_, { getState, rejectWithValue }) => {
        const state = getState() as { templateSettings: TemplateSettingsState };

        const selectedTemplateType = selectTemplateType(state);
        const selectedInvitationStyle = selectInvitationStyle(state);
        const selectedPredefinedTemplateTitle = selectTemplateTitle(state);
        const senderName = selectSenderName(state);
        const businessName = selectBusinessName(state);
        const authToken = state.templateSettings.authToken!;
        const htmlTemplate = selectHtmlTemplate(state);

        try {
          if (selectedTemplateType === TextTemplateType.InvitationStyle) {
            await updateInvitationStyleSettings(authToken, selectedInvitationStyle);
          } else {
            await updateTextTemplate(authToken, [
              {
                templateType: selectedTemplateType,
                templateText: htmlTemplate,
                predefinedTemplateTitle:
                  selectedTemplateType === TextTemplateType.InvitationSMSTemplate ||
                  selectedTemplateType === TextTemplateType.InvitationEmailTemplate
                    ? selectedPredefinedTemplateTitle
                    : undefined,
                isDefault: true,
              },
            ]);

            await updateUserSenderName(authToken, senderName);
            await updateCurrentUserBusinessName(authToken, businessName);

            return {
              updatedUserTemplates: await getTextTemplates(authToken),
            };
          }
        } catch (errorResult) {
          rejectWithValue(errorResult);
        }
      },
      {
        rejected: state => {
          state.saveErrorText = 'Error saving template. Please reload page!';
        },
        pending: state => {
          state.isSaving = true;
        },
        fulfilled: (state, action) => {
          if (action.payload?.updatedUserTemplates) {
            state.userTemplates = action.payload.updatedUserTemplates;
          }
        },
        settled: state => {
          state.isSaving = false;
        },
      },
    ),
  }),
  selectors: {
    selectTemplateType: state => state.selectedTemplateType,
    selectPredefinedTemplates: state => state.predefinedTemplates,
    selectPredefinedTemplate: state => state.selectedPredefinedTemplate,
    selectInitialHtmlTemplate: state => state.initialHtmlTemplate,
    selectTemplateTitle: state => state.selectedPredefinedTemplateTitle,
    selectInvitationStyle: state => state.selectedInvitationStyle,
    selectSenderName: state => state.senderName,
    selectCanSave: state => state.canSave,
    selectBusinessName: state => state.businessName,
    selectBusinessLogoUrl: state => state.businessLogoUrl,
    selectHtmlTemplate: state => state.htmlTemplate,
    selectInputErrors: state => state.inputErrors,
    selectIsSaving: state => state.isSaving,
    selectSaveErrorText: state => state.saveErrorText,
    selectOneTimeSettings: state => state.oneTimeSettings,
    selectSMSPredefinedTemplates: createSelector(
      (state: TemplateSettingsState) => state.predefinedTemplates,
      templates => templates.filter(t => t.type === TextTemplateType.InvitationSMSTemplate),
    ),
    selectEmailPredefinedTemplates: createSelector(
      (state: TemplateSettingsState) => state.predefinedTemplates,
      templates => templates.filter(t => t.type === TextTemplateType.InvitationEmailTemplate),
    ),
  },
});

const replaceHtmlTemplateText = (state: TemplateSettingsState, htmlContent: string) => {
  state.htmlTemplate = state.initialHtmlTemplate = htmlContent;
};

function loadUserTextTemplate(state: TemplateSettingsState) {
  const selectedPredefinedTemplateTitle = selectTemplateTitle({ templateSettings: state });

  if (!selectedPredefinedTemplateTitle || state.selectedTemplateType === TextTemplateType.InvitationStyle) {
    return;
  }

  if (
    state.oneTimeSettings?.predefinedSMSTemplateTitle === selectedPredefinedTemplateTitle &&
    state.selectedTemplateType === TextTemplateType.InvitationSMSTemplate
  ) {
    replaceHtmlTemplateText(state, state.oneTimeSettings.smsTemplate!);
  } else if (
    state.oneTimeSettings?.predefinedEmailTemplateTitle === selectedPredefinedTemplateTitle &&
    state.selectedTemplateType === TextTemplateType.InvitationEmailTemplate
  ) {
    replaceHtmlTemplateText(state, state.oneTimeSettings.emailTemplate!);
  } else {
    const template = state.predefinedTemplates.find(
      t => state.selectedTemplateType === t.type && t.title === selectedPredefinedTemplateTitle,
    );

    state.selectedPredefinedTemplate = template;

    if (template) {
      if (!template.isCustom) {
        replaceHtmlTemplateText(state, template.content);
      } else {
        const userTextTemplate = state.userTemplates.find(
          t => t.templateType === state.selectedTemplateType && t.predefinedTemplateTitle === template.title,
        );

        replaceHtmlTemplateText(
          state,
          userTextTemplate?.predefinedTemplateTitle !== template.title
            ? template.content
            : userTextTemplate.templateText,
        );
      }
    }
  }
}

function populatePredefinedTemplateTitle(state: TemplateSettingsState) {
  if (state.predefinedTemplates.length > 0 && state.userTemplates.length > 0) {
    const templatesOfCurrentType = state.userTemplates.filter(t => t.templateType === state.selectedTemplateType);

    const currentUserTemplate =
      templatesOfCurrentType.length > 1 ? templatesOfCurrentType.find(t => t.isDefault) : templatesOfCurrentType[0];

    const oneTimePredefinedTemplateTitle =
      state.selectedTemplateType === TextTemplateType.InvitationSMSTemplate
        ? state.oneTimeSettings?.predefinedSMSTemplateTitle
        : state.oneTimeSettings?.predefinedEmailTemplateTitle;

    const predefinedTemplateTitle = oneTimePredefinedTemplateTitle ?? currentUserTemplate?.predefinedTemplateTitle;

    if (predefinedTemplateTitle) {
      state.selectedPredefinedTemplateTitle = predefinedTemplateTitle;
    } else {
      const defaultTemplate = state.predefinedTemplates.find(t => state.selectedTemplateType === t.type && t.isDefault);
      if (defaultTemplate) {
        state.selectedPredefinedTemplateTitle = defaultTemplate.title;
      }
    }
  }
}

export const {
  initialLoadAsync,
  clearOneTimeSettings,
  setSelectedTemplateType,
  setSelectedInvitationStyle,
  setSenderName,
  setBusinessName,
  setTemplateTitle,
  updateTemplateContent,
  saveOneTimeSettings,
  saveGloballyAsync,
} = templateSettingsSlice.actions;

export const {
  selectTemplateType,
  selectPredefinedTemplate,
  selectInvitationStyle,
  selectSenderName,
  selectBusinessName,
  selectBusinessLogoUrl,
  selectSMSPredefinedTemplates,
  selectEmailPredefinedTemplates,
  selectTemplateTitle,
  selectInitialHtmlTemplate,
  selectHtmlTemplate,
  selectInputErrors,
  selectCanSave,
  selectIsSaving,
  selectSaveErrorText,
  selectOneTimeSettings,
} = templateSettingsSlice.selectors;

export const templatesSettingsReducer = templateSettingsSlice.reducer;
