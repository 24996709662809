import { APIClient } from './API';
import { InvitationStyleType } from './data-common-types';
import { InvitationStyleSettings, TextTemplate, TextTemplatePayload } from './text-templates-types';
import { PredefinedTextTemplate } from './text-templates-types';

export async function getTextTemplates(token: string): Promise<TextTemplate[]> {
  const payload = await APIClient.AuthorizedGet<TextTemplatePayload>(token, 'text-templates');

  return payload.templates.map(t => ({
    ...t,
  }));
}
export async function updateTextTemplate(token: string, templates: TextTemplate[]): Promise<void> {
  return APIClient.AuthorizedPut(token, 'text-templates', { textTemplates: templates });
}

export async function getPredefinedTextTemplates(token: string): Promise<PredefinedTextTemplate[]> {
  const payload = await APIClient.AuthorizedGet<{ predefinedTemplates: PredefinedTextTemplate[] }>(
    token,
    'predefined-templates',
  );

  return payload.predefinedTemplates;
}

export async function getInvitationStyleSettings(token: string): Promise<InvitationStyleSettings> {
  return await APIClient.AuthorizedGet<InvitationStyleSettings>(token, 'invitation-style-settings');
}

export async function updateInvitationStyleSettings(
  token: string,
  invitationStyle: InvitationStyleType,
): Promise<void> {
  return APIClient.AuthorizedPut(token, 'invitation-style-settings', { invitationStyle });
}
