import { TemplateSettingsState } from './templateSettingsSlice.types';
import { dynamicFieldCodes, TextTemplateType } from 'data/text-templates-types';
import {
  canSaveSMSInvitationTemplate,
  getAllSMSInvitationTemplateAlerts,
  getAllTextTemplateErrors,
  TemplateDynamicField,
} from 'helpers/data-helper';

export function validateTemplateSettings(state: TemplateSettingsState) {
  generateAlerts(state);
  validateInput(state);
}

function validateInput(state: TemplateSettingsState) {
  if (state.senderName.length === 0 || state.businessName.length === 0) {
    state.canSave = false;
    return;
  }

  if (state.selectedTemplateType === TextTemplateType.InvitationSMSTemplate) {
    state.canSave = canSaveSMSInvitationTemplate(state.textTemplate, getDynamicFields(state));
  } else {
    state.canSave = true;
  }
}

function generateAlerts(state: TemplateSettingsState) {
  let errors: string[] = [];

  if (state.selectedTemplateType === TextTemplateType.InvitationSMSTemplate) {
    if (state.selectedPredefinedTemplate && state.textTemplate.length > 0) {
      errors = getAllSMSInvitationTemplateAlerts(state.textTemplate, getDynamicFields(state));
    }
  } else {
    errors = getAllTextTemplateErrors(state.textTemplate, state.selectedTemplateType);
  }

  if (state.senderName.length === 0) {
    errors.push("Sender name can't be empty");
  }

  if (state.businessName.length === 0) {
    errors.push("Business name can't be empty");
  }

  state.inputErrors = errors;
}

const getDynamicFields = (state: TemplateSettingsState): TemplateDynamicField[] => {
  const dynamicFields: TemplateDynamicField[] = [];

  dynamicFields.push({ fieldCode: dynamicFieldCodes.senderName, fieldValue: state.senderName });
  dynamicFields.push({ fieldCode: dynamicFieldCodes.businessName, fieldValue: state.businessName });

  return dynamicFields;
};
