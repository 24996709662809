/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Form, FormGroup } from 'react-bootstrap';
import React, { useState } from 'react';
import { errorColor, gray1 } from '../../styles/app-styles';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import eyeOpenIcon from 'assets/images/password-eye-open.svg';
import eyeCrossedIcon from 'assets/images/password-eye-crossed.svg';

interface Props {
  width?: string;
  onPasswordChange: (password: string) => void;
  addCss?: SerializedStyles;
  errorMessage?: string;
  label?: string;
  value?: string;
  autoFocus?: boolean;
}

export const FormPasswordInput = (props: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <FormGroup
      css={css(
        css`
          ${props.width ? `width: ${props.width};` : ''};
          font-family: 'Circular Std Book';
          border-radius: 40px;
          border: ${props.errorMessage ? '1px solid ' + errorColor : 'none'};
          background: #f9f6f1;
          display: flex;
          height: 56px;
          flex-direction: column;
          padding: 6.5px 24px;
          margin-bottom: 0;
          position: relative;
        `,
        props.addCss,
      )}
    >
      <Form.Label
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: 12px;
          line-height: 160%;
          color: #878d96;
          margin: 0;
          padding: 0;
        `}
      >
        {props.label ?? 'Password'}
      </Form.Label>
      <Form.Control
        autoFocus={props.autoFocus}
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 4px;
          border: none;
          background-color: transparent;
          outline: none;
          border-color: transparent;
          box-shadow: none;
          margin: 0;
          padding-right: ${isPasswordVisible ? '40px' : '0'};
          padding: 0;
          :focus {
            outline: none;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
          }
        `}
        value={props.value ?? ''}
        type={isPasswordVisible ? 'text' : 'password'}
        onChange={e => {
          props.onPasswordChange(e.target.value);
        }}
      />
      <button
        css={css`
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          border: none;
          background-color: transparent;
          cursor: pointer;
          outline: none;
          margin-right: 12px;
        `}
        type="button"
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? (
          <img src={eyeOpenIcon} alt="open" />
        ) : (
          <img src={eyeCrossedIcon} alt="crossed" />
        )}
      </button>
    </FormGroup>
  );
};
