/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Form, FormGroup } from 'react-bootstrap';
import React from 'react';

interface Props {
  width?: string;
  label: string;
  onTextChange: (password: string) => void;
  className?: string;
  css?: SerializedStyles;
  autoFocus?: boolean;
}

export const LoginTextInput = (props: Props) => {
  return (
    <FormGroup
      className={props.className}
      css={css`
        ${props.width ? `width: ${props.width};` : ''};
        ${props.css ? props.css : ''};
        font-family: 'Circular Std Book';
        border-radius: 40px;
        background: #f9f6f1;
        display: flex;
        height: 56px;
        flex-direction: column;
        padding: 6.5px 24px;
      `}
    >
      <Form.Label
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: 12px;
          line-height: 160%;
          color: #878d96;
          margin: 0;
          padding: 0;
        `}
      >
        {props.label}
      </Form.Label>
      <Form.Control
        autoFocus={props.autoFocus}
        css={css`
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 160%;
          border: none;
          background-color: transparent;
          outline: none;
          border-color: transparent;
          box-shadow: none;
          margin: 0;
          padding: 0;

          :focus {
            outline: none;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
          }
        `}
        type="email"
        onChange={e => {
          props.onTextChange(e.target.value);
        }}
      />
    </FormGroup>
  );
};
