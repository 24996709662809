/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { FaSpinner } from 'react-icons/fa';
import { FormSelect } from 'react-bootstrap';
import ratingBlackStar from 'assets/images/rating_black_star.svg';
import ratingGreyStar from 'assets/images/rating_grey_star.svg';
import React from 'react';
import { dangerColor, fontFamily1 } from '../styles/app-styles';

import goldStar from 'assets/images/gold_star.svg';
import goldStarBlank from 'assets/images/gold_star_blank.svg';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
});

Spinner.defaultProps = {
  'aria-label': 'loading',
};

function FullPageSpinner() {
  return (
    <div
      css={{
        fontSize: '4em',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
}

function FullPageErrorFallback({ error }: any) {
  return (
    <div
      role="alert"
      css={{
        color: dangerColor,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function PublicAppFullPageErrorFallback({ error }: any) {
  return (
    <div
      role="alert"
      css={{
        color: dangerColor,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function StarRating({ rating, width, height, spacing, filledStar, unfilledStar }: any) {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      {Array.from({ length: 5 }, (_, i) => (
        <React.Fragment key={i}>
          {i < Math.round(rating) ? (
            <img
              src={filledStar || ratingBlackStar}
              css={{ width: width, height: height, marginRight: i < 4 ? spacing : 0 }}
              alt={`empty star ${i}`}
            />
          ) : (
            <img
              src={unfilledStar || ratingGreyStar}
              css={{ width: width, height: height, marginRight: i < 4 ? spacing : 0 }}
              alt={`filled star ${i}`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

const InTableStarRating = ({ rating, filledStar, unfilledStar }: any) => (
  <StarRating
    rating={rating}
    width={13.3}
    height={12.6}
    spacing={4.3}
    filledStar={filledStar}
    unfilledStar={unfilledStar}
  />
);

const InStatsStarRating = ({ rating }: any) => <StarRating rating={rating} width={15} height={14.2} spacing={5.5} />;

const GoldStarRating = ({ rating }: any) => (
  <StarRating rating={rating} width={20} height={19} spacing={10} filledStar={goldStar} unfilledStar={goldStarBlank} />
);

interface ScreenSizeAdaptableTextProps {
  minWidth?: number;
  maxWidth?: number;
  children: React.ReactNode;
}

const ScreenSizeAdaptableText = ({ minWidth, maxWidth, children }: ScreenSizeAdaptableTextProps) => {
  if (minWidth === undefined && maxWidth === undefined) return <></>;

  let mediaCondition = '@media screen';
  if (minWidth !== undefined) {
    mediaCondition += ` and (min-width: ${minWidth}px)`;
  }
  if (maxWidth !== undefined) {
    mediaCondition += ` and (max-width: ${maxWidth}px)`;
  }

  return (
    <span
      css={css`
        display: none;
        ${mediaCondition} {
          display: initial;
        }
      `}
    >
      {children}
    </span>
  );
};

interface DateProps {
  date: Date;
  color?: string;
  onClick?: () => void;
}

const DateTableCell = ({ date, color, onClick }: DateProps) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  return (
    <td
      css={css`
        color: ${color ? color : '#747474'} !important;
      `}
      onClick={onClick}
    >
      <div>{formattedDate}</div>
    </td>
  );
};

const statusStyleBase = css`
  display: inline-block;
  border-radius: 28px;
  padding: 2px 12px;
  text-align: center;
  font-family: 'Circular Std Book', sans-serif;
`;

const RedStatus = styled.div`
  background-color: #fcedee;
  color: #e96166;
  ${statusStyleBase}
`;

const BlueStatus = styled.div`
  background-color: #e1eefe;
  color: #338bfb;
  ${statusStyleBase}
`;

const GreenStatus = styled.div`
  background-color: #e8fdf1;
  color: #52c082;
  ${statusStyleBase}
`;

const OrangeStatus = styled.div`
  background-color: #fef9ed;
  color: #feb100;
  ${statusStyleBase}
`;

const GreyStatus = styled.div`
  background-color: #f5f1e9;
  color: #747474;
  ${statusStyleBase}
`;

function FeedbackStatusDecorator({ status }: any) {
  switch (status.toLowerCase()) {
    case 'read':
      return <BlueStatus>Read</BlueStatus>;
    case 'unread':
      return <RedStatus>Unread</RedStatus>;
    case 'closed':
      return <GreenStatus>Closed</GreenStatus>;
    case 'in progress':
      return <OrangeStatus>Working</OrangeStatus>;
    default:
      return <>{status}</>;
  }
}

function ReviewStatusDecorator({ status }: any) {
  switch (status.toLowerCase()) {
    case 'read':
      return <BlueStatus>Read</BlueStatus>;
    case 'unread':
      return <RedStatus>Unread</RedStatus>;
    case 'closed':
    case 'in progress':
    case 'responded':
      return <GreenStatus>Responded</GreenStatus>;
    default:
      return <>{status}</>;
  }
}

function UserStatusDecorator({ status }: any) {
  switch (status.toLowerCase()) {
    case 'settochurn':
    case 'set to churn':
      return <RedStatus>Set to churn</RedStatus>;
    case 'renewalfailed':
    case 'renewal failed':
      return <RedStatus>Renewal failed</RedStatus>;
    case 'churned':
      return <RedStatus>Inactive</RedStatus>;
    case 'active':
      return <GreenStatus>Active</GreenStatus>;
    case 'invited':
      return <OrangeStatus>Invited</OrangeStatus>;
    default:
      return <>{status}</>;
  }
}

function InvitationStatusDecorator({ status, friendlyStatus }: any) {
  switch (status.toLowerCase()) {
    case 'opened':
    case 'clickedlink':
      return <BlueStatus>{friendlyStatus}</BlueStatus>;
    case 'smsfailedtosend':
    case 'emailfailedtosend':
    case 'clickednegative':
    case 'submittednegativefeedback':
    case 'clickednegativegoogle':
    case 'clickednegativefacebook':
    case 'clickednegativeyelp':
    case 'clickednegativenocowboys':
      return <RedStatus>{friendlyStatus}</RedStatus>;
    case 'sent':
    case 'sentassms':
    case 'smsdelivered':
    case 'sentfollowup':
    case 'sentfollowupassms':
    case 'sentfollowupassmsdelivered':
    case 'clickedpositive':
    case 'clickedpositivefeedback':
    case 'submittedpositivefeedback':
    case 'clickedpostivegoogle':
    case 'clickedpositivefacebook':
    case 'clickedpositiveyelp':
    case 'clickedpositivenocowboys':
      return <GreenStatus>{friendlyStatus}</GreenStatus>;
    default:
      return <GreyStatus>{friendlyStatus}</GreyStatus>;
  }
}

const BBSelect = styled(FormSelect)`
  border: 1px solid #f1ebe0;
  border-radius: 20px;
  background-color: white;
  height: 38px;
  font-size: 16px;
  font-family: ${fontFamily1};
  font-style: normal;
  font-weight: 450;
  line-height: 160%;
  :focus {
    box-shadow: none;
    border: 1px solid #f1ebe0;
  }
`;

const ContextMenu = ({ anchorPosition, onClose, children }: any) => {
  return (
    <Menu
      open={!!anchorPosition}
      onClose={() => {
        onClose();
      }}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition ? { top: anchorPosition.y, left: anchorPosition.x } : undefined}
      MenuListProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 158,
          padding: '12px 0px',
          gap: 0,
          borderRadius: 12,
          boxShadow:
            '0px 51px 80px 0px rgba(0, 0, 0, 0.05), 0px 19.644px 25.481px 0px rgba(0, 0, 0, 0.03), 0px 4.156px 6.519px 0px rgba(0, 0, 0, 0.02)',
        },
      }}
    >
      {children}
    </Menu>
  );
};

const ContextMenuItem = ({ onClick, children }: any) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {children}
    </MenuItem>
  );
};

const ContextMenuItemIcon = ({ children }: any) => {
  return <ListItemIcon style={{ minWidth: 20, justifyContent: 'center' }}>{children}</ListItemIcon>;
};

const ContextMenuItemText = ({ children }: any) => {
  return <ListItemText style={{ display: 'flex', fontFamily: 'Circular Std Book' }}>{children}</ListItemText>;
};

export {
  FullPageSpinner,
  FullPageErrorFallback,
  PublicAppFullPageErrorFallback,
  ReviewStatusDecorator,
  InvitationStatusDecorator,
  FeedbackStatusDecorator,
  InTableStarRating,
  InStatsStarRating,
  DateTableCell,
  BBSelect,
  UserStatusDecorator,
  RedStatus,
  BlueStatus,
  GreenStatus,
  OrangeStatus,
  GoldStarRating,
  ScreenSizeAdaptableText,
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemIcon,
  ContextMenuItemText,
};
