/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';

import { useAuth } from 'data/providers/AuthProvider';
import { Form } from 'react-bootstrap';
import { BBLink, errorColor, helperMessageStyle, Div20Height } from 'styles/app-styles';
import { BBLogoSignIn } from 'styles/Icons';
import { FormPasswordInput } from 'components/custom-inputs/FormPasswordInput';
import loginImage from 'assets/images/sign_up_image.png';
import { PrimaryButton } from 'components/custom-inputs/PrimaryButton';
import { LabeledFormTextInput } from 'components/custom-inputs/LabeledFormTextInput';
import { FormInputValidationMessage } from 'components/custom-inputs/FormInputValidationMessage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ValidationMapping } from 'data/data-common-types';
import { resetUserPassword } from 'data/user-data';
import { clearValidationMessages, updateValidationMessages } from 'helpers/data-helper';

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordHelpText, setPasswordHelpText] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [passwordConfirmHelpText, setPasswordConfirmHelpText] = useState('');
  const [loginError, setLoginError] = useState('');
  const { login } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const code = location.search.substring(location.search.search('code=') + 5);

  const validationMappings: ValidationMapping[] = [
    { field: 'email', setError: setEmailError },
    { field: 'password', setError: setPasswordError },
    { field: 'confirmPassword', setError: setPasswordConfirmError },
  ];

  useEffect(() => {
    if (!code) {
      setLoginError('There is no code');
    }
  }, []);

  useEffect(() => {
    setPasswordHelpText(password && password.length < 8 ? 'At least 8 characters' : '');

    if (!password || !passwordConfirm) {
      setPasswordConfirmHelpText('');
      return;
    }

    setPasswordConfirmHelpText(password === passwordConfirm ? '' : 'Passwords do not match');
  }, [password, passwordConfirm]);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (passwordHelpText || passwordConfirmHelpText) {
      return;
    }

    clearValidationMessages(validationMappings);
    setLoginError('');
    try {
      await resetUserPassword(code!, email, password, passwordConfirm);
      await login(email, password);
      navigate('/');
    } catch (errorResult: any) {
      if (errorResult.errors) {
        updateValidationMessages(validationMappings, errorResult);
      } else {
        if (errorResult.status) setLoginError(errorResult.status);
      }
    }
  };

  return (
    <div
      css={css`
        height: 100vh;
        color: #262024;
      `}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        css={css`
          border-radius: 20px;
          background-color: white;
          padding: 62px 110px;
          max-width: 524px;
        `}
        className="d-flex flex-column align-items-center"
      >
        <BBLogoSignIn />
        <h3
          css={css`
            margin-top: 38px;
            margin-bottom: 40px;
          `}
        >
          Reset password
        </h3>

        <Form onSubmit={onFormSubmit}>
          <LabeledFormTextInput
            autoFocus={true}
            onTextChange={setEmail}
            errorMessage={emailError}
            value={email}
            label="Email"
            width="400px"
          />
          <FormInputValidationMessage validationError={emailError} />
          <Div20Height />
          <FormPasswordInput
            onPasswordChange={setPassword}
            errorMessage={passwordError}
            label="Enter new password"
            value={password}
            addCss={css({ marginBottom: 0 })}
            width="400px"
          />
          {passwordHelpText && <div css={helperMessageStyle}>{passwordHelpText}</div>}
          <FormInputValidationMessage validationError={passwordError} />
          <Div20Height />
          <FormPasswordInput
            onPasswordChange={setPasswordConfirm}
            errorMessage={passwordConfirmError}
            label="Confirm password"
            value={passwordConfirm}
            addCss={css({ marginBottom: 0 })}
            width="400px"
          />
          {passwordConfirmHelpText && <p css={helperMessageStyle}>{passwordConfirmHelpText}</p>}
          <FormInputValidationMessage validationError={passwordConfirmError} />

          {loginError && (
            <div
              css={css`
                margin-top: 10px;
                margin-left: 18px;
              `}
            >
              <p
                css={css`
                  font-size: 12px;
                  font-weight: 450;
                  margin-bottom: 0;
                  color: ${errorColor};
                `}
              >
                {loginError}
              </p>
            </div>
          )}

          <PrimaryButton
            type="submit"
            className="w-100"
            css={css`
              margin-top: 40px;
              margin-bottom: 24px;
            `}
          >
            Reset password
          </PrimaryButton>

          <div
            css={css`
              width: 400px;
              margin-top: 40px;
              color: #262024;
              font-size: 12px;
              display: flex;
              justify-content: center;
            `}
          >
            &nbsp;
            <BBLink target="_blank" href="https://betterbunch.com/terms-of-service">
              Terms of Service
            </BBLink>
            &nbsp; and &nbsp;
            <BBLink target="_blank" href="https://betterbunch.com/our-privacy-policy">
              Privacy Policy.
            </BBLink>
          </div>
        </Form>
      </div>
      <div
        css={css`
          margin-left: 40px;
        `}
      >
        <img css={{ maxWidth: 600, maxHeight: 600 }} src={loginImage} alt="login" />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
