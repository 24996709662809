/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { MouseEventHandler } from 'react';
import {
  fontFamily1,
  fontSize,
  gray2,
  gray3,
  primary1,
  primary2,
  primary3,
} from '../../styles/app-styles';

interface Props {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  addCss?: SerializedStyles;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  maxWidth?: string;
  height?: string;
}

export const PrimaryButton = ({
  children,
  type,
  className,
  addCss,
  onClick,
  disabled,
  height,
  maxWidth,
}: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      onClick={onClick}
      css={css(
        css`
          background-color: ${disabled ? gray2 : primary1};
          border-color: ${disabled ? gray2 : primary1};
          border-style: solid;
          border-radius: 40px;
          font-family: ${fontFamily1};
          font-size: ${fontSize};
          color: ${disabled ? gray3 : 'white'};
          height: ${height ?? '58px'};
          padding-left: 24px;
          padding-right: 24px;
          max-width: ${maxWidth};
          :hover {
            background-color: ${disabled ? gray2 : '#4F424A'};
            border-color: ${disabled ? gray2 : '#4F424A'};
          }
          :focus {
            background-color: ${'#110F10'};
            outline-color: ${'#110F10'};
            border-color: ${'#110F10'};
          }
        `,
        addCss,
      )}
    >
      {children}
    </button>
  );
};
